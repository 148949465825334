import { defineComponent, reactive } from '@vue/composition-api';
import axios from '@/plugins/axios';
import router from '@/router';
export default defineComponent({
    setup() {
        const alert = reactive({
            type: 'info',
            icon: {
                name: 'mdi-loading',
                spin: true
            },
            text: 'Se activeaza...'
        });
        const urlParts = window.location.href.split('activate/');
        if (urlParts.length !== 2) {
            router.push({ name: 'home' });
        }
        const activate = () => {
            alert.type = 'info';
            alert.icon.name = 'mdi-loading';
            alert.icon.spin = true;
            alert.text = 'Se activeaza...';
            return axios.get(`activate/${urlParts.pop()}`)
                .then(() => {
                alert.type = 'success';
                alert.icon.name = 'mdi-check-circle-outline';
                alert.icon.spin = false;
                alert.text = 'Contul a fost activat. Dupa redirectionare, va puteti autentifica...';
            })
                .catch(e => {
                alert.type = 'error';
                alert.icon.spin = false;
                alert.icon.name = 'mdi-alert-circle-outline';
                alert.text = e.response?.data?.message || e;
            })
                .finally(() => {
                setTimeout(() => {
                    router.push({ name: 'home' });
                }, 1500);
            });
        };
        activate();
        return {
            alert
        };
    }
});
